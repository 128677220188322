///
/// Head and body
///

:root,
body {

  &,
  &[style] {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

:root {

  &,
  &[style] {
    @include set-background($window-background);

    overflow-y: scroll;
    text-size-adjust: 100%;
    color: $base-text-color;

    @if $debug ==true {
      &::before {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        min-height: 100vh;
        background-image: repeating-linear-gradient(to bottom, #000, #000 1px, transparent 1px, transparent 1rem);
        background-size: 100%;
        pointer-events: none;
        z-index: 1000;
        opacity: 0;
      }
    }
  }
}

///
/// Global pseudo classes
///

:disabled {
  @extend %disabled;
}

:focus {
  outline: none;
}

::selection {
  @include set-background($selection-background);
}


///
/// Global elements' attributes
///

[hidden] {
  @extend %hidden;
}

[disabled],
[aria-disabled='true'] {
  @extend %disabled;
}


///
/// Scrollbar styling
///

::-webkit-scrollbar {
  width: map-get($scrollbar-config, width);

  &-track {
    background-color: map-get($scrollbar-config, background);
  }

  &-thumb {
    background: map-get($scrollbar-config, thumb);
    border-radius: map-get($scrollbar-config, radius);

    &:window-inactive {
      background: map-get($scrollbar-config, thumb);
    }

    &:hover {
      background: map-get($scrollbar-config, hover);
    }
  }
}

.k-chip {
  font-family: 'MuseoSans', sans-serif;
}

/* PAGER */
.k-pager-nav {
  display: inline-block;
}

.k-label {
  display: inline-block;
  margin: 0;
}

@media only screen and (max-width: 1024px) {
  .k-pager-info.k-label {
    display: block;
    text-align: center;
  }

  .k-grid-pager {
    text-align: center;
  }

  .k-pager-nav {
    min-width: 1rem;
  }
}

.k-grid-norecords-template {
  border: none;
}

.highlighted {
  background-color: #fff3cd;
}

html {
  scroll-behavior: smooth;
}

.react-tooltip {
  border-radius: 20px;
}